<template>
  <div>
    <!-- 
      Feed Type Buttons 
    -->


    <!-- 
      Feed 
    -->
    <div v-if="feedType == 'home-feed' || feedType == 'booking' || feedType == 'classifieds'">
      <PostFeed :feed-type="feedType" :post-genres="postGenres"  :post-scenes="postScenes" :show-settings="showSettings"/>
    </div>
    <!-- 
      Concerts 
    -->
    <div v-if="feedType == 'concerts'">
      <PostFeed feed-type="concerts" :post-genres="postGenres"  :post-scenes="postScenes" :show-settings="showSettings"/>
    </div>
    <!-- Artists -->
    <div v-if="feedType == 'artist'">
      <ProfilesWrapper profile-type="artist" :limit="0" :scene-id="sceneId" />
    </div>
    <!-- People -->
    <div v-if="feedType == 'people'">
      <ProfilesWrapper profile-type="user" :limit="0" :scene-id="sceneId" />
    </div>
    <!-- Pages -->
    <div v-if="feedType == 'venue'">
      <ProfilesWrapper profile-type="venue" :limit="0" :scene-id="sceneId" />
    </div>
    <!-- Pages -->
    <div v-if="feedType == 'business'">
      <ProfilesWrapper profile-type="business" :limit="0" :scene-id="sceneId" />
    </div>
	</div>

</template>

<script>
import {} from '@ionic/vue';
import { defineComponent, computed, ref, onMounted } from 'vue';
import { settings, apps, reorderThree, location, options, search, musicalNotes, close, image, play, caretDown, caretUp } from 'ionicons/icons'
import PostFeed from './PostFeed.vue';
import ProfilesWrapper from './profiles/ProfilesWrapper.vue';
import { useStore } from "vuex";
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'FeedWrapper',
  props: ['sid'],
  components: {
    PostFeed, ProfilesWrapper
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const authUser = computed(() => store.state.authUser);
    const postGenres = ref([]);
    const postScenes = ref([]);
    const feedType = ref('home-feed');
    const showFeedType = ref(false);
    const showSettings = ref(false);
    const sceneId = ref(0);

    onMounted(() => {
      if(authUser.value) {
        if(props.sid > 0) {
          sceneId.value = props.sid
        } else {
          sceneId.value = authUser.value.scene_id
        }
        
        if(authUser.value.genres) {
          postGenres.value = []
          for(var i=0;i<authUser.value.genres.length;i++) {
            postGenres.value.push(authUser.value.genres[i].id)
          }
        }
      }
    })

    function updateRoute(val) {
      router.push(val)
    }

    return {
      authUser, postGenres, postScenes, router, updateRoute, settings, apps, reorderThree, location, options, search, musicalNotes, feedType, showFeedType, close, image, play, caretDown, caretUp, showSettings, sceneId
    }
  },
  watch: {
    authUser: {
      handler() {
        if(this.authUser) {
          if(this.sid > 0) {
            this.sceneId = this.sid
          } else {
            this.sceneId = this.authUser.scene_id
          }
          if(this.authUser.genres) {
            this.postGenres = []
            for(var i=0;i<this.authUser.genres.length;i++) {
              this.postGenres.push(this.authUser.genres[i].id)
            }
          }
        }
      },
      deep:true
    },
    feedType: function() {
      this.showFeedType = !this.showFeedType     
    },
    sid: function() {
      if(this.sid > 0) {
        this.sceneId = this.sid
      }
    }
  }
});
</script>
<style scoped>
  #feed-type ion-segment {
    padding:8px 1px;
    border-radius: 0px!important;
    --background: var(--ion-color-light-tint);

  }
  #feed-type ion-segment-button {
    border: 1px solid var(--ion-color-medium);
    --indicator-box-shadow: transparent!important;
    margin-right: 5px;
    margin-left: 2px;
    --background: var(--ion-color-light-tint);
    --color: var(--ion-color-dark);
    --background-checked: var(--ion-color-primary);
    --color-checked: white;
    --indicator-color : transparent!important; /* this solution */
    min-width: auto;
    font-weight:bold;
  }
  ion-button {
    margin:2px 2px 0px 0px;
    --padding-end: 0.75em;
    --padding-start: 0.75em;
    --background: var(--ion-color-light);
    --color: var(--ion-color-dark);
  }
  ion-icon {
    font-size:14px;
  }
</style>
