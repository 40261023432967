<template>
	<div>
		<div id="profile-views">
      <ion-card>

        <div style="padding-bottom:0px;margin-bottom:0px;">
          <ion-item lines="none">
            <div tabindex="0"></div>
            <ion-select value="popular" ok-text="Select" cancel-text="Close" v-model="profileView">
              <ion-select-option value="popular">Popular</ion-select-option>
              <ion-select-option value="online">Online Now</ion-select-option>
              <ion-select-option value="newest">Newest</ion-select-option>
              <ion-select-option value="random">Random</ion-select-option>
            </ion-select>
          </ion-item>
          
        </div>
      </ion-card>
		</div>

		<div>
			<ProfileAvatars :profile-type="profileType" :limit="0" view="full" :scene-id="sceneId" />
		</div>	
	</div>
</template>

<script>
import { IonCard, IonItem, IonSelect, IonSelectOption } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import ProfileAvatars from '../ProfileAvatars.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'ProfilesWrapper',
  props: ['profileType', 'sceneId'],
  components: {
    IonCard, IonItem, IonSelect, IonSelectOption, ProfileAvatars
  },
  setup() {
    const profileView = ref('popular');
    const router = useRouter();

    function updateProfileView(val) {
      profileView.value = val
    }

    function updateRoute(val) {
      router.push(val)
    }

    function goBack() {
      router.go(-1)
    }

    return {
      updateProfileView, profileView, updateRoute, goBack 
    }
  }
});
</script>
<style scoped>
  ion-searchbar {
    margin-top:0px;
    margin-bottom:5px;
    height:50px;
  }

  ion-card {
    margin:0px 0px 10px 0px;
    border-radius: 0px;
  }
</style>