<template>
  <ion-page>
    <div v-if="hashtag != null && hashtag != undefined">
      <Header page="hashtag" :username="'#'+hashtag" />
      <ion-content color="light" :fullscreen="true">
        <FeedWrapper feed-type="feed" :profile-id="0" :place-id="0" :hashtag="hashtag" />
      </ion-content>
    </div>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from '@ionic/vue';
import { defineComponent, computed } from 'vue';
import Header from '../components/Header.vue';
import { chevronDownCircleOutline } from 'ionicons/icons'
import FeedWrapper from '../components/FeedWrapper.vue';
import { useStore } from "vuex";

export default defineComponent({
  name: 'HashtagFeed',
  props: ['hashtag'],
  components: {
    IonPage,
    Header,
    FeedWrapper, IonContent
  },
  setup() {
    const store = useStore();
    const authUser = computed(() => store.state.authUser);

    const doRefresh = (event) => {
      console.log('Begin async operation');
      store.commit('setFeedTrigger', true);
      setTimeout(() => {
        console.log('Async operation has ended');
        event.target.complete();
      }, 2000);
    }

    return {
      Header, authUser, chevronDownCircleOutline, doRefresh
    }
  }
});
</script>